import React, { lazy, Suspense } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import App from './App'
import { ModalProfileCard } from './components/common'
import ScrollToTop from './components/common/scroll-to-top'
import { PrivateRoute } from './components/navigation'
import * as routes from './constants/routes'
import * as serviceWorker from './serviceWorker'
import store from './store/store'
import './styles/index.scss'
import './index.css'
import { statistics } from './utils/stats'

import './i18n'

const MainPage = lazy(() => import('./pages/main-page'))
const ConsiderationApplications = lazy(() => import('./pages/consideration-applications-page'))
const ConferenceApplicationsPage = lazy(() => import('./pages/conference-applications-page'))
const ExhibitionApplicationsPage = lazy(() => import('./pages/exhibition-applications-page'))
const ManualMailingPage = lazy(() => import('./pages/manual-mailing-page'))
const StatisticsPage = lazy(() => import('./pages/statistics-page'))
// const BroadcastPage = lazy(() => import('./pages/broadcast-page'))
const ConferenceMaterialPage = lazy(() => import('./pages/conference-material-page'))
const AboutPage = lazy(() => import('./pages/about-page'))
const ConferencePage = lazy(() => import('./pages/conference-page'))
const ExhibitionPage = lazy(() => import('./pages/exhibition-page'))
const SectionsPage = lazy(() => import('./pages/sections-page'))
const NewsPage = lazy(() => import('./pages/news-page'))
const PresentationPage = lazy(() => import('./pages/presentation-page'))
const TopicPage = lazy(() => import('./pages/topic-page'))
const CompetencePage = lazy(() => import('./pages/competence-page'))
const CompetenciesPage = lazy(() => import('./pages/competencies-page'))
const EventsPage = lazy(() => import('./pages/events-page'))
const ChampionshipsPage = lazy(() => import('./pages/championships-page'))
const ParticipantsPage = lazy(() => import('./pages/participants-page'))
const GalleryPage = lazy(() => import('./pages/gallery-page'))
const ContactsPage = lazy(() => import('./pages/contacts-page'))
const CommitteeContactsPage = lazy(() => import('./pages/committee-contacts-page'))
const AlbumPage = lazy(() => import('./pages/album-page'))
const FAQPage = lazy(() => import('./pages/FAQ-page'))
const SurveysPage = lazy(() => import('./pages/surveys-page'))
const SurveysAdminPage = lazy(() => import('./pages/survey-admin-page'))
const NewPassword = lazy(() => import('./components/forms/new-password/new-password'))
const StaticPage = lazy(() => import('./components/static-page/static-page'))
const Error404 = lazy(() => import('./pages/errors/404'))
const UserProfilePage = lazy(() => import('./pages/user-profile'))
const VotingPage = lazy(() => import('./pages/voting-page'))

statistics()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={"Loading"}>
          <App>
            <Suspense fallback={false}>
              <ScrollToTop>
                <Switch>
                  <Route exact path={routes.MAIN} component={MainPage} />
                  {/*<Route exact path={routes.BROADCAST} component={BroadcastPage} />*/}
                  <Route path={[
                      routes.ADMIN_REGISTRATION, 
                      routes.EXHIBITION_REGISTRATION, 
                      routes.CONFERENCE_REGISTRATION_EXC,
                    ]} component={MainPage} 
                  />
                  <PrivateRoute
                    exact
                    path={routes.CONSIDERATION_APPLICATIONS}
                    component={ConsiderationApplications}
                  />
                  <PrivateRoute
                    exact
                    path={routes.EXHIBITION_APPLICATIONS}
                    component={ExhibitionApplicationsPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.CONFERENCE_APPLICATIONS}
                    component={ConferenceApplicationsPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.MANUAL_MAILING}
                    component={ManualMailingPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.STATISTICS}
                    component={StatisticsPage}
                  />
                  <Route path={routes.CONFERENCE_MATERIALS} component={ConferenceMaterialPage} />
                  <Route path={routes.ABOUT} component={AboutPage} />
                  <Route path={routes.CONFERENCE} component={ConferencePage} />
                  <Route path={routes.EXHIBITION} component={ExhibitionPage} />
                  <Route path={routes.SECTIONS} component={SectionsPage} />
                  <Route
                    path={[routes.NEWS_DETAIL, routes.NEWS]}
                    component={NewsPage}
                  />
                  <PrivateRoute path={[routes.PRESENTATION_DETAIL, routes.PRESENTATION]} component={PresentationPage} />
                  <PrivateRoute exact path={routes.COMPETENCY_TOPIC} component={TopicPage} />
                  <Route
                    path={[
                      routes.COMPETENCY,
                      routes.COMPETENCY_FORUM,
                    ]}
                    component={CompetencePage}
                  />
                  <Route path={routes.COMPETENCIES} component={CompetenciesPage} />
                  <Route
                    path={[
                      routes.EVENT,
                      routes.EVENTS,
                      routes.EVENTS_PERIOD,
                    ]}
                    component={EventsPage}
                  />
                  <Route
                    path={[
                      routes.CHAMPIONSHIPS,
                      routes.CHAMPIONSHIPS_ALL,
                    ]}
                    component={ChampionshipsPage}
                  />
                  <Route path={[routes.PARTICIPANTS]} component={ParticipantsPage} />
                  <Route path={[routes.PARTICIPANTS]} component={ParticipantsPage} />
                  <Route exact path={routes.GALLERY} component={GalleryPage} />
                  <Route path={routes.GALLERY_ALBUM} component={AlbumPage} />
                  <Route path={routes.CONTACTS} component={ContactsPage} />
                  <Route path={routes.COMMITTEE_CONTACTS} component={CommitteeContactsPage} />
                  <Route path={routes.FAQ} component={FAQPage} />
                  <PrivateRoute exact path={routes.SURVEYS} component={SurveysPage} />
                  <PrivateRoute path={routes.SURVEYS_ADMIN} component={SurveysAdminPage} />
                  <PrivateRoute exact path={routes.SURVEY} component={SurveysPage} />
                  <PrivateRoute path={routes.SURVEYS_PASSED} component={SurveysPage} />
                  <PrivateRoute path={routes.SURVEY_COMPLETED} component={SurveysPage} />
                  <PrivateRoute path={[routes.USER_PROFILE_VIEWED, routes.USER_PROFILE]} component={UserProfilePage} />
                  {/* <PrivateRoute path={routes.USER_PROFILE} component={UserProfilePage} /> */}
                  <Route path={routes.RESET_PASSWORD} component={NewPassword} />
                  <Route
                    path={[
                      routes.VOTING,
                      routes.VOTING_HISTORY,
                      routes.VOTING_EDIT,
                    ]}
                    component={VotingPage}
                  />
                  <Route path={routes.SLUG} component={StaticPage} />
                  <Route path={routes.ALL} component={Error404} />
                </Switch>
              </ScrollToTop>
            </Suspense>
            <ModalProfileCard />
          </App>
        </Suspense>
      </DndProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
