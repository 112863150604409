import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import * as Models from '../../api/model/models'
import { AcademicDegree, Citizenship, Material, RegistrationApplicationPost } from '../../api/model/models'
import { AxiosResponse } from 'axios'
import appAPI from '../../api/api'

type AccountState = {
  user: Models.UserWSGetPrivate | undefined
  loading: boolean
  clearUser: boolean
  needVisa: boolean
  consulateAddress: string
  hotelAddress: string
  hotelWebsite: string
  language: 'Ru' | 'Eng'
  academicDegree: boolean
  isLecture: boolean
  academicDegreeOptions: AcademicDegree[]
  citizenship: Citizenship[]
  materials: Material[]
  materialsLoading: boolean
}

export declare type ActionAddMatcher = PayloadAction<
  AxiosResponse | undefined,
  string,
  { arg: string; requestId: string; aborted: boolean; condition: boolean },
  SerializedError
>

const initialUserState: AccountState = {
  user: undefined,
  loading: false,
  clearUser: false,
  needVisa: false,
  consulateAddress: '',
  hotelAddress: '',
  hotelWebsite: '',
  language: 'Ru',
  academicDegree: false,
  isLecture: false,
  academicDegreeOptions: [],
  citizenship: [],
  materials: [],
  materialsLoading: false,
}

export const fetchMaterials = createAsyncThunk('fetchMaterials', async (payload, { rejectWithValue }) => {
  try {
    return await appAPI.materials.getAll()
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

export const fetchCitizenship = createAsyncThunk('fetchCitizenship', async (payload, { rejectWithValue }) => {
  try {
    return await appAPI.citizenship.getAll()
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

export const fetchAcademicDegrees = createAsyncThunk('fetchAcademicDegrees', async (payload, { rejectWithValue }) => {
  try {
    return await appAPI.academicDegrees.getAll()
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

export const registrationApplication = createAsyncThunk(
  'registrationApplication',
  async (payload: RegistrationApplicationPost, { rejectWithValue }) => {
    try {
      return await appAPI.register.registrationApplication(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const fetchLogin = createAsyncThunk(
  'fetchLogin',
  async (payload: Models.CustomTokenObtainPair, { rejectWithValue }) => {
    try {
      return await appAPI.account.login(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const fetchProfile = createAsyncThunk('fetchUser', async (payload, { rejectWithValue }) => {
  try {
    return await appAPI.account.getProfile()
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

export const fetchLogOut = createAsyncThunk('fetchLogOut', async () => await appAPI.account.logout())

export const fetchResetPassword = createAsyncThunk(
  'fetchResetPassword',
  async (payload: Models.SendEmailReset, { rejectWithValue }) => {
    try {
      return await appAPI.account.resetPassword(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const fetchPasswordConfirm = createAsyncThunk(
  'fetchPasswordConfirm',
  async (payload: Models.PasswordResetConfirm, { rejectWithValue }) => {
    try {
      return await appAPI.account.passwordConfirm(payload)
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

export const clearUserData = createAsyncThunk('clearUserData', async () => {
  await appAPI.account.clearTokens()
})

const accountSlice = createSlice({
  name: 'account',
  initialState: initialUserState,
  reducers: {
    setNeedVisaStatus: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.needVisa = payload
    },
    setConsulateAddress: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.consulateAddress = payload
    },
    setHotelAddress: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.hotelAddress = payload
    },
    setHotelWebsite: (state: AccountState, { payload }: PayloadAction<string>) => {
      state.hotelWebsite = payload
    },
    setLanguage: (state: AccountState, { payload }: PayloadAction<'Ru' | 'Eng'>) => {
      state.language = payload
    },
    setAcademicDegree: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.academicDegree = payload
    },
    setIsLecture: (state: AccountState, { payload }: PayloadAction<boolean>) => {
      state.isLecture = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCitizenship.fulfilled, (state, { payload }) => {
        state.citizenship = payload.data
      })
      .addCase(fetchAcademicDegrees.fulfilled, (state, { payload }) => {
        state.academicDegreeOptions = payload.data
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProfile.fulfilled, (state, { payload }) => {
        state.loading = false
        if (payload?.data) state.user = payload.data
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.loading = false
      })

      .addCase(fetchMaterials.pending, (state) => {
        state.materialsLoading = true
      })
      .addCase(fetchMaterials.fulfilled, (state, { payload }) => {
        state.materialsLoading = false
        state.materials = payload.data
      })
      .addCase(fetchMaterials.rejected, (state) => {
        state.materialsLoading = false
      })
      .addCase(fetchLogOut.fulfilled, (state) => {
        state.user = initialUserState.user
      })
      .addCase(fetchLogOut.rejected, (state) => {
        state.user = initialUserState.user
      })
      .addCase(clearUserData.fulfilled, (state) => {
        state.user = initialUserState.user
        state.clearUser = false
      })
      .addMatcher(
        (action): action is ActionAddMatcher => action.type.endsWith('/rejected'),
        (state, action) => {
          const payload = action.payload as
            | {
                status: number | string
                messages?: { detail: string }
              }
            | undefined
          if (!payload) return

          //  if the tokens expire, we get 401
          //  if we deactivate the user
          //  if we enter an incorrect login password
          if (payload.status === 401) {
            state.clearUser = true
          }
        },
      )
  },
})

export const accountActions = {
  ...accountSlice.actions,
  registrationApplication,
  fetchMaterials,
  fetchCitizenship,
  fetchAcademicDegrees,
  fetchLogin,
  fetchProfile,
  fetchLogOut,
  fetchResetPassword,
  fetchPasswordConfirm,
  clearUserData,
}

export default accountSlice.reducer
